const React = require("react");
const Layout = require("./src/components/Layout").default;
require('./src/styles/global.scss');

// Fonts
require('./src/styles/typography.css');
require('@fontsource/roboto-condensed')
require('@fontsource/roboto-condensed/700.css')

// Swiper styles
require('swiper/css');
require('swiper/css/navigation');
require('swiper/css/autoplay');

exports.wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>
      {element}
    </Layout>
  );
};