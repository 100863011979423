import React from 'react'

const IconLink = ({ Icon, ...props}) => {
  return (
    <a {...props}>
      <Icon />
      <span className="visually-hidden">{props.title}</span>
    </a>
  )
}

export default IconLink