import React from "react";
import Navbar from "./Navbar";
import { Link } from "gatsby";
import { FaWhatsapp } from 'react-icons/fa'
import { Analytics } from '@vercel/analytics/react';

import * as styles from '../../styles/components/layout.module.scss';

export default function Layout({ children, location }) {
  return (
    <div style={{ height: "100vh", gridTemplateRows: "auto minmax(0, 1fr)" }}>
      <Navbar location={location} />
      {children}
      <Analytics />
      <Link
        href={`https://wa.me/+27761962970`}
        style={{
          backgroundColor: '#25d366',
          color: '#fff',
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '24px',
          textDecoration: 'none',
          zIndex: 1000,
        }}
        className={styles.whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp />
      </Link>
    </div>
  );
}
